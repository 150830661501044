
import { defineComponent, ref, computed, reactive, onMounted, watchEffect } from 'vue'
import { store } from "@/store";
import { FormInstance } from 'element-plus';
import Customers from '@/core/services/Callable/Customers';
import Validations from '@/core/services/etc/Validations';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from '@/router/clean';
import { etc, rule, notification } from '@/store/stateless';
import Invoice from '@/core/services/Callable/Invoice';
import { InvoiceMutationTypes } from '@/store/enums/Invoice/Mutations';

export default defineComponent({
    setup() {
        const ruleFormRef = ref<FormInstance>()

        const health = computed(() => {
            return store.state.customer.health;
        })

        const loading = computed(() => {
            return store.state.customer.detailLoading
        })

        const clickLoading = computed(() => {
            return store.state.invoice.loading
        })

        const invoice = computed(() => {
            return store.state.invoice.invoice;
        })

        const invoiceData = computed(() => {
            return store.state.invoice.invoiceData;
        })

        const openModal = computed(() => {
            return store.state.invoice.openInvoiceModal;
        });

        const isError = ref<boolean>(false);
        const isSubAmend = ref(true);
            
        const closeModal = (e) => {
            store.commit(InvoiceMutationTypes.SET_INVOICE_MODAL, false);
        };

        const rules = reactive({
            // insurance_type: [{ validator: Validations.insurance_type, trigger: ['blur'] }],
            // insurance_plan: [{ validator: Validations.insurance_plan, trigger: ['blur', 'change'] }],
            policy_price: [{ validator: (rule, value, callback) => {
                Validations.refund_amount(rule, value, callback, Number(invoiceData.value.policy_price)); // Pass 18 as a custom parameter
            } , trigger: ['blur', 'change'] } ],
            // invoice_amount: [{ validator: Validations.invoice_amount, trigger: ['blur', 'change'] }],
            // sales_price: [{ validator: Validations.sales_price, trigger: ['blur', 'change'] }],
            // client_classification: [{ validator: Validations.client_classification, trigger: ['blur', 'change'] }],
            // discount_amount: [{ validator: Validations.discount_amount, trigger: ['blur', 'change'] }],
            payment_type: [{ validator: Validations.payment_type, trigger: ['blur', 'change'] }],
            // pending_payment_type: [{ validator: Validations.payment_type, trigger: ['blur', 'change'] }],
            // order_description: [{ validator: Validations.order_description, trigger: ['blur', 'change'] }],
            // reference_no: [{ validator: Validations.reference_no, trigger: ['blur', 'change'] }],
            // payment_date: [{ validator: Validations.payment_date, trigger: ['blur', 'change'] }],
            // installments: [{ validator: Validations.installments, trigger: ['blur', 'change'] }],
            // file_receipt: [{ validator: Validations.file_receipt, trigger: ['blur', 'change'] }],
            // expiry_date_time: [{ validator: Validations.expiry_date_time, trigger: ['blur', 'change'] }],
        })

        let Receipt: any;
        let chequeReceipt: any;

        const handleFileUpload = (file) => {
            Receipt = file;
        };

        const handleChequeFileUpload = (file) => {
            chequeReceipt = file;
        }

        const revalidate = () => {
            if (isSubmitted.value) {
                ruleFormRef.value?.validate((valid) => {
                    if (!valid) {
                        // 
                    }
                })
            }
        }

        const isSubmitted = ref(false)
        const top = ref()
        const middle = ref()
        const last = ref()
        const membersCount = ref(0)
        const invoiceRef = ref()
        const amendInvoice = async (formEl: FormInstance | undefined) => {
            Validations.is_reference_no_exist = false
            // console.log(policyData.value)
            invoice.value.members = health.value.members

            isSubmitted.value = true
            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                // console.log(valid,fields)

                if (valid) {

                    const newMembers = health.value.members

                    if(membersCount.value == newMembers.length)
                    {
                        notification.warning(
                            'Amend Invoice',
                            'Delete atleast one member to proceed'
                        );
                        isSubmitted.value = false
                        return;
                    }

                    if(newMembers.length == 0)
                    {
                        notification.warning(
                            'Amend Invoice',
                            'Atleast one member should be present'
                        );
                        isSubmitted.value = false
                        return;
                    }

                    const formData = new FormData();

                    for (const key in invoice.value) {
                        formData.set(key, invoice.value[key]?.toString() ?? '');
                    }
                    

                    const lead_id = router.currentRoute.value.params.lead_id.toString();
                    formData.set('member_details', JSON.stringify(newMembers))
                    formData.set('customer_id', health.value.id ? health.value.id.toString() : '')
                    formData.set('lead_id', lead_id)

                    const result = await Invoice.subAmendInvoice(formData);
                    // console.log(result); 
                    if (result.status == 200) {


                        notification.success(
                            'Invoice',
                            'Invoice made successfully'
                        );
                        // goLists(health.value.id);

                        // window.open(result.data.data.file_path, '_blank');
                    } else {
                       if(result.response?.data?.data?.reference_no) {
                            
                            Validations.is_reference_no_exist = true
                            rule.customerDetails = 'reference_no'
                            setTimeout(() => {
                                formEl.validateField('reference_no')
                                invoiceRef.value.focus()
                            }, 200);
                        } 
                    }
                }
                else {
                    rule.customerDetails = Object.keys(fields)[0]
                    console.log('rules',rule.customerDetails)
                     if(invoiceRef.value) invoiceRef.value.focus();
                }
            })
        }


        const addNewMember = () => {
            let relationship = <any>null
            if(health.value.insured_type_id == 7) relationship = "Referral"

            health.value?.members?.unshift({
                id: null,
                insured_type_id: null,
                insured_city: null,
                member_name: null,
                relationship,
                gender: null,
                dob: null,
                nationality_id: null,
                is_salary: null,
                declaration_answers: [],
                is_married: false
            })
        }




        onMounted(async () => {
            setCurrentPageBreadcrumbs("Amend Invoice ", [{ label: "Invoices", value: "invoiceApproval" }]);
            await Customers.getLeadsByLeadId({
                id: parseInt(router.currentRoute.value.params.lead_id.toString())
            }).then(() => {
                window.scrollTo(0, 750);
            })

            const invoiceDetails = await Invoice.getInvoice({
                invoice_id: parseInt(router.currentRoute.value.params.invoice_id.toString())
            })

            console.log('invoiceDetails', invoiceDetails);
            invoice.value.invoice_id = invoiceDetails?.id
            invoice.value.insurance_plan = invoiceDetails?.insurance_policy_id
            invoice.value.client_classification = invoiceDetails?.client_classification
            invoice.value.quote_ref_no = invoiceDetails?.merchant_reference
            
            invoice.value.members = health.value.members
            membersCount.value = health.value.members.length
        })

        const goLists = (customer_id: any) => {
            router.replace({ name: 'CustomerDetails', params: { customer_id } })
        }

        watchEffect(() => {
            if(health.value && health.value.members)
            {
                if(health.value.members.length <= 1)
                    isSubAmend.value = false;
                else
                    isSubAmend.value = true;

                invoice.value.members = health.value.members

            }
        })


        const checkform = () => {
            const myForm: any = document.querySelectorAll('#last')
            console.log(myForm)
        }

        return {
            store,
            clickLoading,
            checkform,
            top,
            middle,
            last,
            revalidate,
            loading,
            rule,
            rules,
            health,
            ruleFormRef,
            etc,
            goLists,
            amendInvoice,
            handleFileUpload,
            handleChequeFileUpload,
            invoice,
            openModal,
            isError,
            closeModal,
            addNewMember,
            isSubAmend,
            invoiceRef
        }
    },
})
